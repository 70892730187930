/* Footer.css */
.footer {
    background-color: rgba(27, 39, 51, 0.9); /* Slight transparency for wave visibility */
    color: #fff;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    position: relative;
    z-index: 2;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    z-index: 3; /* Ensures it stays above the waves */
}

.footer-logo,
.footer-links,
.footer-contact {
    flex: 1 1 200px;
    min-width: 200px;
}

.footer-logo img {
    max-width: 150px;
    margin-bottom: 10px;
}

.footer-logo p {
    font-size: 14px;
    line-height: 1.5;
}

.footer-links h4,
.footer-contact h4 {
    font-size: 16px;
    margin-bottom: 10px;
    border-bottom: 2px solid #f77c3f;
    display: inline-block;
    
}

.footer-links ul,
.footer-contact ul {
    list-style-type: none;
    padding: 0;
}

.footer-links li,
.footer-contact li {
    font-size: 14px;
    margin: 5px 0;
    cursor: pointer;
    
}

.footer-links li:hover,
.footer-contact li:hover {
    color: #f77c3f;
}

.footer-contact p {
    font-size: 14px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    /* Centers contact text */
}

.footer-payment img {
    width: 100%;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid #333;
    font-size: 14px;
    position: relative;
    z-index: 3; /* Keeps this section above the waves */
    text-align: center;
    flex-direction: column; /* Centers content in footer-bottom on mobile */
}

.footer-social a {
    color: #fff;
    margin: 0 10px;
    font-size: 18px;
    text-decoration: none;
}

.footer-social a:hover {
    color: #f77c3f;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    
    .footer-links,
    .footer-contact {
        text-align: left; /* Centers content in each section */
    }
    .footer-logo, .footer-contact
    {
        text-align: center;
    }

    .footer-logo img {
        margin: 0 auto;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    .footer-social {
        margin-top: 10px;
    }
    .footer-contact p
    {
        justify-content: center;
    }
}

/* Arrow for Quick Links */
.footer-links li::before {
    content: '\2192'; /* Right arrow character */
    font-size: 14px;
    color: #f77c3f;
    margin-right: 8px;
    display: inline-block;
    transition: transform 0.3s ease;
}

.footer-links li:hover::before {
    transform: translateX(4px); /* Adds a small animation on hover */
}

/* Style for Contact Icons */
.footer-contact i {
    color: #f77c3f;
    margin-right: 8px;
    font-size: 16px;
}

/* Wave background adjustments */
.wave-background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1; /* Ensures the wave stays behind all footer content */
}
