* {
    box-sizing: border-box;
  }
  
  body {
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .finance_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  .heading_part
  {
    border-radius: 20px;
    border: 1px solid white;
    margin: 3%;
  }
  .heading_part h1, p
  {
    padding-left: 20px;
    padding-right: 20px ;
    padding-top: 10px;

  }
  
  .finance_card {
    background: linear-gradient(145deg, #3a3a3a, #505050);
    border-radius: 15px;
    padding: 20px;
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    perspective: 1000px;
  }
  
  .finance_card:hover {
    transform: scale(1.05) rotateY(5deg);
  }
  
  .finance_cardContent {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .finance_imageContainer img {
    width: auto;
    padding: 5px;
    height: auto;
    border: 1px solid white;
    max-height: 150px;
    object-fit: contain;
    border-radius: 10px;
  }
  
  .finance_name {
    font-size: 1.5rem;
    margin: 15px 0;
    color: #ffea00;
  }
  
  .finance_description {
    font-size: 0.9rem;
    color: #d3d3d3;
    margin-bottom: 20px;
  }
  
  .finance_priceAndButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .finance_buyButton {
    padding: 10px 20px;
    border: none;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    background-color: #6a0dad;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .finance_buyButton:hover {
    background-color: #7b1fa2;
  }
  
  /* Media query for mobile responsiveness */
  @media (max-width: 600px) {
    .finance_grid {
      grid-template-columns: 1fr;
    }
  }
  .price_freeze_section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  border-radius: 15px;
  margin: 3%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  perspective: 1000px;
}

.freeze_text, .freeze_logo {
  flex: 1 1 45%;
  color: white;
  transform-style: preserve-3d;
}

.freeze_text h2 {
  font-size: 2rem;
  padding: 3%;
  margin-bottom: 20px;
  color: white;
}

.freeze_text p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #d3d3d3;
}

.apply_freeze_button {
  padding: 10px 20px;
  background-color: #6a0dad;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.apply_freeze_button:hover {
  background-color: #7b1fa2;
  transform: scale(1.05);
}

.freeze_logo img {
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 10px;
  transform: translateZ(20px) scale(1.05);
}

@media (max-width: 768px) {
  .price_freeze_section {
    flex-direction: column;
    padding: 2%;
  }
  
  .freeze_text, .freeze_logo {
    flex: 1 1 100%;
  }
}
