.about {
    min-height: 100vh;
    width: 100vw;
    padding: 20px 50px 50px 50px;
    background: black;
    color: rgba(255, 255, 255, 0.8);
    
  }
  .about h1
  {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 4rem;
    font-style: oblique;
  }
  .about p
  {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-style: oblique;
  }
  
  iframe {
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  iframe:last-child {
    padding-right: 20px;
  }
  
  .videos {
    transform: translateX(-50px);
    display: flex;
    overflow-x: hidden;
    width: 100vw;
    background-color: #990000;
    background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
  }
  
  .videos::-webkit-scrollbar {
    height: 10px;
    outline: none;
  }
  
  .videos::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: white;
  }
  
  @media (max-width: 500px) {
    .videos::-webkit-scrollbar {
      display: none;
    }
  }
  