/* Main button to trigger the popup */
.Search_section_open-popup-btn {
  padding: 10px 20px;
  background-color: #5bc0de;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 20px;
}

/* Overlay styling (now acts as main container) */
.Search_section_popup-overlay {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
}

/* Content area */
.Search_section_popup-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  text-align: center;
  margin: 20px auto;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

/* Close button (optional for page view, but keeping for reusability) */
.Search_section_close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Heading */
.Search_section_heading {
  color: black;
  font-size: 24px;
  margin-top: 20px;
  font-weight: bold;
}

/* Message text */
.Search_section_message {
  color: #777;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Contact button */
.Search_section_contact-btn {
  padding: 10px 15px;
  border: 1px solid #5bc0de;
  background-color: transparent;
  color: #5bc0de;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 20px;
}

/* Social icons */
.Search_section_social-icons i {
  margin: 0 10px;
  font-size: 24px;
  color: #777;
  cursor: pointer;
}

/* Icon container */
.Search_section_icon-container {
  margin-bottom: 20px;
  position: relative;
}

.Search_section_pour-icon {
  width: 50px;
  height: 50px;
  background: teal;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}

.Search_section_checkmark-icon {
  width: 50px;
  height: 50px;
  background: teal;
  clip-path: polygon(25% 50%, 50% 75%, 75% 0);
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Responsiveness */
@media (max-width: 768px) {
  .Search_section_popup-content {
    width: 95%;
    padding: 20px;
  }
  .Search_section_heading {
    font-size: 20px;
  }
  .Search_section_message {
    font-size: 14px;
  }
  .Search_section_social-icons i {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .Search_section_heading {
    font-size: 18px;
  }
  .Search_section_message {
    font-size: 12px;
  }
  .Search_section_social-icons i {
    font-size: 18px;
  }
}
