/* FreeConsultation.css */
.consultation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black; /* Blue background */
  color: white;
  padding: 0px 20px 20px 20px;
  margin: 20px;
  box-sizing: border-box;
  flex-wrap: wrap; /* To make it responsive */
}

.text-section {
  flex: 1;
  margin-right: 20px;
}

.call-text {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.text-section h1 {
  font-size: 5rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
  font-style: oblique;
}

.text-section p {
  font-size: 36px;
}

.button-container {
  display: flex;
  gap: 10px;
}

.button-container .call-button,
.request-button {
  padding: 10px 20px;
  border: none;
  background-color: white;
  color: #1d4db7;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.call-button:hover,
.request-button:hover {
  background-color: #f0f0f0;
}

.image-section {
  flex: 1;
  margin: 0px;
  text-align: right;
}

.consultation-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .consultation-container {
      flex-direction: column;
      text-align: center;
  }

  .image-section {
      margin-top: 20px;
  }

  .text-section h1 {
      font-size: 28px;
  }

  .call-button,
  .request-button {
      width: 100%;
  }

  .button-container {
      flex-direction: column;
      gap: 10px;
  }
}
