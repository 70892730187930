* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  background: #19191a;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0px !important;
  margin: 0px !important;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}


.call-button
{
  background-color: #790cac;
  border: none;
}
.call-button:hover
{
  background-color: #660892;
  border: none;
}
.whatsapp-button
{
  background-color: #790cac;
  border: none;
}
.whatsapp-button:hover
{
  background-color: #660892;
  border: none;
}
.navbar-logo {
  flex-basis: 20%;
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  width: auto;
  border: 3px solid white;
  border-radius: 20px 20px 90px 30px; 
  
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  flex-basis: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  margin: 0px !important;
  padding: 0px !important;
}

.nav-item {
  height: 40px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #790cac;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0px !important;
  padding-right: 1rem !important;
  height: 100%;
}

.activated {
  color: #790cac;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 90px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #19191a;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #09bef0;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: -27px;
    left: -7px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
/* Dropdown Menu Styling */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #19191a;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #790cac;
}
@media screen and (max-width: 960px) {
  .dropdown-content {
    position: static; /* Adjust positioning for mobile screens */
    width: 100%;      /* Ensure the dropdown takes up full width */
  }
  
  .dropdown-content a {
    padding: 10px;
    text-align: center; /* Center-align the text on mobile */
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #660892; /* Keep hover consistent with other buttons */
  }
}
/* Show dropdown on hover for desktop */
@media screen and (min-width: 960px) {
  .nav-item.dropdown:hover .dropdown-content {
    display: block;
  }
}

.active-page-title {
  position: sticky;
  top: 90px; /* Adjust to be below navbar */
  background-color: #790cac;
  color: white;
  font-size: 1.2rem;
  border-radius: 0 0 15px 15px;
  width: 100%;
  z-index: 900; /* Lower than navbar, but higher than other content */
  text-align: center;
  white-space: nowrap;
  padding: 0;
  transition: opacity 0.3s ease; /* Smooth transition */
}
