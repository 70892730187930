/* LeadCaptureForm.css */

/* Background and layout */
.lead-capture-form {
  background: rgba(0, 0, 0, 1) ;
  background-size: cover;
  padding: 1rem; /* Reduced padding */
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  color: #fff;
  width: 100%; /* Adjusted to 80% width */
  max-width: 1300px; /* Added max-width to limit size */
  margin: 0 auto; /* Center form */
}

.container-fluid {
  background-color: rgba(255, 255, 255, 0.1); 
  padding: 1rem; /* Reduced padding */
  border-radius: 10px;
  max-width: 100%;
}

/* Section title styling */
.section-title {
  text-align: center;
  font-size: 1.4rem; /* Slightly reduced title font size */
  color: #fff;
  margin-bottom: 1rem; /* Reduced margin */
  font-family: 'Georgia', serif;
}

/* Form control labels */
.form-label {
  color: #fff;
  font-weight: bold;
  font-size: 0.85rem; /* Further reduced label font size */
}

/* Form input styles */
.form-control {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  margin-bottom: 0.5rem; /* Reduced margin between form elements */
  padding: 0.4rem; /* Reduced input padding */
  font-size: 0.85rem; /* Reduced font size in inputs */
}

.form-control::placeholder {
  color: #ddd;
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border-color: #ffc107;
  box-shadow: none;
}

/* Submit button */
.submit-btn {
  background-color: #ffc107;
  color: #000;
  padding: 0.4rem 0.8rem; /* Reduced button padding */
  border: none;
  font-size: 0.85rem; /* Reduced button font size */
  font-weight: bold;
  text-align: center;
  display: block;
  margin: 0.8rem auto; /* Reduced margin */
  width: 35%; /* Made the button narrower */
  border-radius: 5px;
}

.submit-btn:hover {
  background-color: #e0a800;
}

/* Optional responsive tweaks */
@media (max-width: 768px) {
  .lead-capture-form {
    padding: 0.8rem;
    width: 90%; /* Increase width on small screens */
  }

  .section-title {
    font-size: 1.3rem;
  }

  .form-control {
    margin-bottom: 0.6rem;
    font-size: 0.8rem;
  }

  .submit-btn {
    font-size: 0.8rem;
    width: 65%; /* Adjust button width on small screens */
  }
}

/* Two-column layout adjustments */
@media (min-width: 768px) {
  .form-group {
    margin-bottom: 0.5rem; /* Reduced margin between form groups */
  }

  .row > .col-md-6 {
    padding-left: 0.4rem; /* Reduced horizontal space between columns */
    padding-right: 0.4rem;
  }
}
/* Form input and select styles */
.form-control {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff; /* White text color for inputs */
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  margin-bottom: 0.5rem; /* Reduced margin between form elements */
  padding: 0.4rem; /* Reduced input padding */
  font-size: 0.85rem; /* Reduced font size in inputs */
}

.form-control::placeholder {
  color: #ddd;
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  border-color: #ffc107;
  box-shadow: none;
}

/* Fix for select dropdown text */
.form-control select {
  color: #000; /* Black text color for select options */
  background-color: rgba(255, 255, 255, 0.2); /* Ensure background matches */
}

/* On hover, keep the color unchanged */
.form-control select:hover {
  color: #000;
}
